import React from 'react';
import './aboutheader.css';



const Aboutheader = () => (

  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Talent Associates Talent Acquisition Consulting Services </h1>

      <p>At Talent Associates we have one eye on the current requirements and the other on the future needs. As well as search and selection, we build out recruitment programs to generate a flow of quality candidates with the skills you need over time.</p>
      <p>Talent Associates build bespoke talent pipeline programs with our clients, micro-targeting the candidate base with ongoing added value beyond the unique employment opportunities your company offers, and maintain long term engagement with mission critical talent pools. We offer opportunities for face-to-face meet-ups in social settings, whilst building light-touch contact points for motivated candidates to inquire and begin a dialogue.</p>
      <p>We strive to provide comprehensive talent acquisition engagement, doing things differently in a competitive employment market, and give our clients a key point of differentiation.</p>

      {/* <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button" 
        className='OpenModalBtn'>
            Get Started</button>
      </div> */}

      {/* <div className="gpt3__header-content__people">
        <img src={people} alt='people'/>
        <p>Highly motivated, actively engaged specialists professionals and executives. AI sourced, human qualified</p>
      </div> */}
    </div>

    {/* <div className="gpt3__header-image">
      <img src={ai} alt='ai' />
    </div> */}
  </div>
);

export default Aboutheader;
