import React, { useState, useRef } from 'react';
import gpt3Logo from '../../assets/talent-logo.svg';
import './footer.css';
import { Link } from 'react-router-dom';
import Modal from '../../components/modal/Modal';
import emailjs from '@emailjs/browser';
import styled from "styled-components";

const Form = styled.form`
  margin-bottom: 1rem;
  font-family: var(--font-family);
  color: #fff;
  input {
    background: var(--color-footer);
    display: flex;
    line-height: 28px;
    font-size: 20px;
    font-family: var(--font-family);
    border: 2px solid var(--color-footer);
    padding: 1 1rem;
    outline: none;
    color: #fff;
  }
  textarea {    
    background: var(--color-footer);
    display: flex;
    width: 500px:
    font-family: var(--font-family);
    line-height: 28px;
    font-size: 20px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    line-height: 1.8;
     }
`

const Footer = () => {
  const form = useRef();
  const [isOpen, setOpen] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_21hcrm2', 'template_gg9qts6', form.current, 'Dw_UFQ6UqqqbY1UHw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
    <>

  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Do you want to take advantage of advanced talent acquisition tools today, before your talent competitors do, tomorrow?</h1>
    </div>
    <button className="gpt3__footer-btn" onClick={() => setOpen(true)}>Request a consultant call</button>
      <Modal isOpen={isOpen} close={() => setOpen(false)}>
        <div>
        <Form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" style={{ width: "400px"}}/>
      <label>Email</label>
      <input type="email" name="user_email" style={{ width: "400px"}}/>
      <label>Message</label>
      <textarea name="message" style={{ width: "500px"}}/> <br />
      <button className="gpt3__footer-btn" type="submit" value="Send" style={{ cursor: "pointer", display: "center", width: "60px", height: "30px", font: "gpt3__footer-btn p"  }}>Send</button>
    </Form>
  
      </div>
      </Modal>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={gpt3Logo} alt="gpt3_logo" />
        <p> US and international search, selection and talent acquisition consulting. <br /> All Rights Reserved.</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p><Link to='./About'>About</Link></p>
        <p ><a href="https://www.thehrdirector.com" rel="noopener" target="_blank" >The HR Director</a></p>
        <p> <a href="https://www.shrm.org" rel="noopener" target="_blank" >Society for Human Resource Management</a></p>        
        <p></p>
      </div>

      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <p>New York, NY</p>
        <p>+1 212 353 8049</p>
        <p>team@talent-associates.com</p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>@2021 Talent Associates. All rights reserved.</p>
    </div>
  </div>
  </>
    );
  };

export default Footer;

//  , display: "center", width: "60px", height: "30px", color: "#000",