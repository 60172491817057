import React from 'react'
import { Footer, Consultingservices } from '../containers';
import { Nav2, Aboutheader } from '../components';
import '../index.css';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-JQPBSW8KTD"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const About = () => {
  return (
    <>
    <div>
       <Nav2 id="home"/>
      </div>
      <div>
      <Aboutheader />
      <Consultingservices />
      <Footer />
      </div>

    
    </>
  )
}

export default About

