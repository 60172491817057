import React, { useState, useRef } from 'react';
import './cta.css';
import Modal from '../../components/modal/Modal';
import emailjs from '@emailjs/browser';
import styled from "styled-components";

const Form = styled.form`
  margin-bottom: 1rem;
  font-family: var(--font-family);
  color: #fff;
  input {
    background: var(--color-footer);
    display: flex;
    line-height: 28px;
    font-size: 20px;
    font-family: var(--font-family);
    border: 2px solid var(--color-footer);
    padding: 1 1rem;
    outline: none;
    color: #fff;
  }
  textarea {    
    background: var(--color-footer);
    display: flex;
    width: 500px:
    font-family: var(--font-family);
    line-height: 28px;
    font-size: 20px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    line-height: 1.8;
     }
`

const CTA = () => {
  const form = useRef();
  const [isOpen, setOpen] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_21hcrm2', 'template_gg9qts6', form.current, 'Dw_UFQ6UqqqbY1UHw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
  <div className="gpt3__cta">
    <div className="gpt3__cta-content">
      <p>Request Early Access to Get Started</p>
      <h3>Register Today & start exploring the endless possibilities.</h3>
    </div>
    <div className="gpt3__cta-btn">
      <button type="button" onClick={() => setOpen(true)}>Get Started</button> 
    </div>
    <Modal isOpen={isOpen} close={() => setOpen(false)}>
        <div>
        <Form ref={form} onSubmit={sendEmail}>
      <label>Name</label>
      <input type="text" name="user_name" style={{ width: "400px"}}/>
      <label>Email</label>
      <input type="email" name="user_email" style={{ width: "400px"}}/>
      <label>Message</label>
      <textarea name="message" style={{ width: "500px"}}/> <br />
      <button className="gpt3__footer-btn" type="submit" value="Send" style={{ cursor: "pointer", display: "center", width: "60px", height: "30px", font: "gpt3__footer-btn p"  }}>Send</button>
    </Form>
  
      </div>
      </Modal>
  </div>
)
};

export default CTA;

