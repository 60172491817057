import React from 'react';
import Feature from '../../components/feature/Feature';
import './whattalentasso.css';
import { Link } from 'react-router-dom';

const Whattalentasso = () => (
  <div className="gpt3__whatgpt3 section__margin" id="whotalentasso">
    <div className="gpt3__whatgpt3-feature">
      <Feature title="Who is Talent Associates" text="Before all else Talent Associates are Search and Selection professionals, elevated by innovation and a technological focus. Identifying, qualifying and placing talent with dynamic clients offering accelerated career paths is our core mission. We've developed tools to widen our breadth then narrowcast to our targets, to attract passive candidates and match them with the best fit client requirements. AI technologies are a tool we wield in our processes applied, by the Human Intelligence of our Talent Acquisition specialist team." />
      <Feature title="Services" text="Talent Associates can be engaged on retained or contingency for search and selection services utilising our expertise incorporating AI technologies into our processes to produce more closely matched candidates and faster times to hire. Talent Associatse can also be engaged on a consulting basis where you have a higher level Talent Acquisition program requirement." />
    </div>
    <div className="gpt3__whatgpt3-heading">
      <h1 className="gradient__text">Broaden your talent demographics. Accelerate your time to hire.</h1>
      <p><Link to='../About/#nav2'>Comprehensive Talent Acquisition Consulting Services</Link></p>

    </div>
    <div className="gpt3__whatgpt3-container">
      <Feature title="Search & Selection" text="Talent pool building, candidate targeting and outreach, active sourcing and recruitment consulting with the candidate base. We build your value in the employee market and tell your unique story whilst evaluating candidates against the requirements of the roles." />
      <Feature title="Talent Pipeline Optimization" text="At Path Search Partners we have one eye on the current requirements and the other on the future needs. We build out talent acquistion systems, processes and programs to generate a flow of quality candidates with the skills you need over time." />
      <Feature title="Brand Building & Brand Messaging" text="Building and executing consistent outreach programs and building talent pipelines to match your growth plans well into the future. Telling your compelling story, your company features and benefits to narrow audiences, separating and differentiating your company from the pack and reaching candidates, competitor personnel and executives in your niche verticals. " />
    </div>
  </div>
);

export default Whattalentasso;

