import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle } from "styled-components";
import { useSpring, animated } from 'react-spring';
import closeIcon from "../../assets/talent-logo.svg";

const portalRoot = document.getElementById("portal-root");

const Background = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(4, 44, 84, 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Content = styled.div`
  background-color: var(--color-heading-text);
  line-height: 28px;
  padding: 20px;
  border-radius: 10px;
  width: 1200px:
  max-width: 90%;
  max-height: 90%;
  box-shadow: 0 3px 15px -3px rgba(0, 0, 0, 0.2);
  position: relative;
`;

const HeaderRow = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`;

const ScrollDisabler = createGlobalStyle`
  body {
    overflow-y: hidden;
  }
`;


const Modal = ({ isOpen, close, children }) => {
  const contentRef = useRef();

  const animation = useSpring({
    config: {
      duration: 250
    },
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateY(0%)` : `translateY(-100%)`
  });

  useEffect(() => {
    if (!isOpen) return;

    function listener(evt) {
      if (contentRef.current?.contains(evt.target)) return;
      close();
    }

    window.addEventListener("click", listener);

    return () => {
      window.removeEventListener("click", listener);
    };
  }, [isOpen]);

  if (!isOpen) return null;
  return ReactDOM.createPortal(
    <>
      <Background>
      <animated.div style={animation}>
        <Content ref={contentRef}>
          <HeaderRow>
            <img
              src={closeIcon}
              alt=""
              width="50px"
              style={{ cursor: "pointer" }}
              onClick={close}
            />
          </HeaderRow>
          {children}
        </Content>
        </animated.div>
      </Background>
      <ScrollDisabler />
    </>,
    portalRoot
  );
};

export default Modal;
