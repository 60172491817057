import React from 'react'
import { Footer, Possibility, Whattalentasso, Header, Features } from './containers';
import { CTA, Brand, Navbar } from './components';
import './App.css';
import './index.css';
import ReactGA from 'react-ga';
const TRACKING_ID = "G-JQPBSW8KTD"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);



const App = () => {
  return (
    <div classname= 'App'>
      <div className='gradient__bg__app'>
      <Navbar />
      <Header />
      <Brand />
      <Whattalentasso />
      <Features />
      <CTA />   
      <Possibility />
      <Footer />

      </div>

    </div>
  )
}

export default App

