import React, { useState, useRef } from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import Modal from '../../components/modal/Modal';
import emailjs from '@emailjs/browser';
import styled from "styled-components";

const Form = styled.form`
  margin-bottom: 1rem;
  font-family: var(--font-family);
  color: #fff;
  input {
    background: var(--color-footer);
    display: flex;
    line-height: 28px;
    font-size: 20px;
    font-family: var(--font-family);
    border: 2px solid var(--color-footer);
    padding: 1 1rem;
    outline: none;
    color: #fff;
  }
  textarea {    
    background: var(--color-footer);
    display: flex;
    width: 500px:
    font-family: var(--font-family);
    line-height: 28px;
    font-size: 20px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    line-height: 1.8;
     }
`

const Possibility = () => {

  const form = useRef();
  const [isOpen, setOpen] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_21hcrm2', 'template_gg9qts6', form.current, 'Dw_UFQ6UqqqbY1UHw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  
  return (
    <>
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">AI platforms<br /> for targeted Search and Selection</h1>
      <p>Talent Associates is technologically engaged, employing Artificial Intelligence technologies and tools in our candidate targeting and sourcing methodologies. Going forward, our engagement with AI will only deepen.</p> 
      <p> OpenAI offers natural language processing capabilities previously unheard of in the corporate tools marketplace, whilst Google AI offers an AI platform and tools for both structured and unstructured datasets allowing us search wide as well as deep, for the right candidate profiles.</p> 
      <p> We are continuing to build AI powered automation tools - generating new innovations in the assessment step within the Talent Acquisition process.</p>
      <div className="gpt3__cta-btn">
      <button type="button" onClick={() => setOpen(true)}>Request contact by one of our consultants</button> 
      </div>
    </div>

        <Modal isOpen={isOpen} close={() => setOpen(false)}>
          <div>
            <Form ref={form} onSubmit={sendEmail}>
              <label>Name</label>
              <input type="text" name="user_name" style={{ width: "400px"}}/>
              <label>Email</label>
              <input type="email" name="user_email" style={{ width: "400px"}}/>
              <label>Message</label>
              <textarea name="message" style={{ width: "500px"}}/> <br />
              <button className="gpt3__footer-btn" type="submit" value="Send" style={{ cursor: "pointer", display: "center", width: "60px", height: "30px", font: "gpt3__footer-btn p"  }}>Send</button>
            </Form>
    
          </div>
        </Modal>
    
  </div>
  </>
);

}
export default Possibility;