import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import './header.css';



const Header = () => (

  <div className="gpt3__header section__padding" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Talent Associates </h1>
      <p>Creating the path from mission critical talent requirements to onboarding the right hires.</p>

      {/* <div className="gpt3__header-content__input">
        <input type="email" placeholder="Your Email Address" />
        <button type="button" 
        className='OpenModalBtn'>
            Get Started</button>
      </div> */}

      <div className="gpt3__header-content__people">
        <img src={people} alt='people'/>
        <p>Highly motivated, actively engaged specialist professionals and executives. AI sourced, human qualified</p>
      </div>
    </div>

    <div className="gpt3__header-image">
      <img src={ai} alt='ai' />
    </div>
  </div>
);

export default Header;
