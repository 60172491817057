import React, { useState, useRef } from 'react';
import './Consultingservices.css';
import Modal from '../../components/modal/Modal';
import emailjs from '@emailjs/browser';
import styled from "styled-components";
import Consultingservice from '../../components/consultingservice/Consultingservice';

const Form = styled.form`
  margin-bottom: 1rem;
  font-family: var(--font-family);
  color: #fff;
  input {
    background: var(--color-footer);
    display: flex;
    line-height: 28px;
    font-size: 20px;
    font-family: var(--font-family);
    border: 2px solid var(--color-footer);
    padding: 1 1rem;
    outline: none;
    color: #fff;
  }
  textarea {    
    background: var(--color-footer);
    display: flex;
    width: 500px:
    font-family: var(--font-family);
    line-height: 28px;
    font-size: 20px;
    border: 2px solid var(--color-footer);
    padding: 0 1rem;
    outline: none;
    color: #fff;
    line-height: 1.8;
     }
`

const ConsultingservicesData = [
  {
    title: 'Research',
    text: 'Top down engagement with C-Suite to line management with a focus on the mission critical talent landscape and forward requirements. Bottom up research with skilled professionals and what they need from their future team members. Competitor analysis, demographic analysis, cross-vertical analysis, training and development pipeline analysis.',
  },
  {
    title: 'Talent Mapping',
    text: 'Demographics of desirable skilled individuals : where they live, who they work for, where they were educated and where is the organisations blindspots and new sourcing opportunities',
  },
  {
    title: 'Strategy and Analytics',
    text: 'Development of talent acquisition strategy tailored to the strengths of the organisation, the key unique value proposition the organisation offers the candidate base. Execution of passive candidate search, social media branding, advertising and email marketing each with analytics with A/B testing, data insights and iteration.',
  },
  {
    title: 'Search and Selection',
    text: 'Micro-targeted search and selection for mission critical talent pools, evaluation and assessment and presentation to client for acquisition engagement process',
  },
  {
    title: 'Brand Building',
    text: 'Social media, online marketing and in-person meetups to mission critical talent pools, building awareness and desirability of the client as the place to continue to develop their career',
  },
  {
    title: 'Talent Pipeline Execution',
    text: 'Automation, execution and analysis of talent pipeline programs, building effective processes compelling to the candidate',
  },
];

const Consultingservices = () => {
  const form = useRef();
  const [isOpen, setOpen] = useState(false);


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_21hcrm2', 'template_gg9qts6', form.current, 'Dw_UFQ6UqqqbY1UHw')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };
  
  return (
    <>
  
    <div className="gpt3__features section__padding" id="features">
      <div className="gpt3__features-heading">
        <h1 className="gradient__text">Full Service Search & Selection Consulting and Talent Acquisition Strategy Execution</h1>
        <div className="gpt3__features-heading p">
            <p>Analyse the talent marketplace for the mission critical talent you are looking to employ.</p><br />
            <p>Development of data analytics and insights the talent marketplace for the skills you are looking to employ</p>
            <p>Formulate a plan to find, attract and retain the talent you need for explosive growth in line with budget and organisational goals.</p>
            <p>Execute  the talent acquisition strategy in coordination with internal teams, internal tools, AI tools, and external partners.</p>
            <p>Generate data analytics on the market for process or focus improvement.</p>
            <p>Develop and execute of on-going candidate marketing programs, iterating on metrics and feedback.</p>
      {/* <button type="button" onClick={() => setOpen(true)}>Request contact by one of our consultants to Get Started</button>  */}
    </div>

        <Modal isOpen={isOpen} close={() => setOpen(false)}>
          <div>
          <Form ref={form} onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="user_name" style={{ width: "400px"}}/>
        <label>Email</label>
        <input type="email" name="user_email" style={{ width: "400px"}}/>
        <label>Message</label>
        <textarea name="message" style={{ width: "500px"}}/> <br />
        <button className="gpt3__footer-btn" type="submit" value="Send" style={{ cursor: "pointer", display: "center", width: "60px", height: "30px", font: "gpt3__footer-btn p"  }}>Send</button>
      </Form>
    
        </div>
        </Modal>
      </div>
      <div className="gpt3__features-container">
        {ConsultingservicesData.map((item, index, img) => (
          <Consultingservice title={item.title} img={item.img} text={item.text} key={item.title + index} />
        ))}
      </div>
    </div>
  </>
  )
};

export default Consultingservices;
